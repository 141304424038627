"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./img/*.{png,jpg,jpeg,gif,svg}";

// import stylu
import "../index.scss";

// carousel
import "./carousel";

// SmoothScroll inicializace
import SmoothScroll from "smooth-scroll";
new SmoothScroll('a[href*="#"]', {
	header: "header",
	speed: 300,
	speedAsDuration: true,
});

// NodeList.prototype.forEach() polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}

// galerie SimpleLightbox - BEGIN
// https://simplelightbox.com
import SimpleLightbox from "simplelightbox";
import "simplelightbox/src/simple-lightbox.scss";
window.addEventListener("DOMContentLoaded", () => {
	document.querySelectorAll("*[data-gallery]").forEach((gallery) => {
		new SimpleLightbox("#" + gallery.getAttribute("id") + " a", {
			/* options */
		});
	});
});
// galerie SimpleLightbox - BEGIN

import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";
document.addEventListener("DOMContentLoaded", function () {
	const lightbox = GLightbox({
		touchNavigation: true,
		loop: true,
		autoplayVideos: true,
	});
});

// clickToggleClass - BEGIN
window.addEventListener("DOMContentLoaded", () => {
	document.querySelectorAll("*[data-click-toggle-class]").forEach((elm) => {
		elm.addEventListener("click", (event) => {
			event.preventDefault();
			const classes = event.target.dataset.clickToggleClass.split("|");
			const targets = event.target.dataset.clickToggleTarget
				? [...document.querySelectorAll(event.target.dataset.clickToggleTarget)]
				: [event.target];
			const toggledClases = targets[0].classList.contains(classes[0])
				? {
						remove: classes[0],
						add: classes[1],
				  }
				: {
						remove: classes[1],
						add: classes[0],
				  };
			targets.forEach((elm) => {
				elm.classList.remove(toggledClases.remove);
				elm.classList.add(toggledClases.add);
			});
		});
	});
});
window.addEventListener("DOMContentLoaded", () => {
	const menuToggle = document.getElementById("toggleMenu");
	menuToggle.addEventListener("click", (event) => {
		if (menuToggle.classList.contains("open")) {
			menuToggle.classList.remove("open");
		} else {
			menuToggle.classList.add("open");
		}
	});
});
// clickToggleClass - END

//filter acction
window.addEventListener("DOMContentLoaded", () => {
	const f_all = document.getElementById("f_all");
	const f_video = document.getElementById("f_video");
	const f_audio = document.getElementById("f_audio");
	if (f_all) {
		const boxesAll = document.querySelectorAll(".audiovideo__item");
		const boxesVideo = document.querySelectorAll(".audiovideo__video");
		const boxesAudio = document.querySelectorAll(".audiovideo__audio");

		f_all.addEventListener("click", () => {
			boxesAll.forEach((box) => {
				box.classList.remove("audiovideo__hidden");
			});
      f_video.classList.remove("active");
      f_audio.classList.remove("active");
      f_all.classList.add("active");
		});

    f_video.addEventListener("click", () => {
			boxesAll.forEach((box) => {
				box.classList.remove("audiovideo__hidden");
			});
      boxesAudio.forEach((box) => {
				box.classList.add("audiovideo__hidden");
			});
      f_video.classList.add("active");
      f_audio.classList.remove("active");
      f_all.classList.remove("active");
		});

    f_audio.addEventListener("click", () => {
			boxesAll.forEach((box) => {
				box.classList.remove("audiovideo__hidden");
			});
      boxesVideo.forEach((box) => {
				box.classList.add("audiovideo__hidden");
			});
      f_video.classList.remove("active");
      f_audio.classList.add("active");
      f_all.classList.remove("active");
		});
	}
});
//modal fucntion
window.addEventListener("DOMContentLoaded", () => {
	const modal = document.getElementById("modal");
	if (typeof modal != "undefined" && modal != null) {
		// ZAVRENI MODALU
		const modalClose = document.getElementById("modalClose");
		modalClose.addEventListener("click", (event) => {
			event.preventDefault();
			modal.classList.add("modal__hidden");
		});

		//OTEVRENI MODALU
		const modalBody = document.getElementById("modalBody");

		document.querySelectorAll("*[data-open-modal]").forEach((elm) => {
			elm.addEventListener("click", (event) => {
				event.preventDefault();
				modal.classList.remove("modal__hidden");
				modalBody.scrollIntoView();
			});
		});
	}
});

//scroll na modal
window.addEventListener("DOMContentLoaded", () => {
	const modal = document.getElementById("modal");
	if (typeof modal != "undefined" && modal != null) {
		if (!modal.classList.contains("modal__hidden")) {
			document.getElementById("modalBody").scrollIntoView();
		}
	}
});
