// carousel
import { tns } from "tiny-slider/src/tiny-slider";
import "tiny-slider/dist/tiny-slider.css";

//CAROUSEL OPEN
var carouselHome = document.getElementById("f_slider");
if (typeof carouselHome != "undefined" && carouselHome != null) {

  let sliderF = tns({
    container: "#f_slider",
    items: 1,
    slideBy: "page",
    autoplay: false,
    mouseDrag: true,
    gutter: 16,
    speed: 400,
    lazyload: true,
    nav: false,
    controls: true,
    controlsText: ["", ""],
    responsive: {
      640: {
        items: 2
      },
      880: {
        items: 3
      }
    }
  });
}
